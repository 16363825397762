<template>
	<div class="back-to-top col" align-x="center" :style="{ width: parseWidth, color }" @click="bockToTop()">
		<b style="margin-bottom:10px;">Back to Top</b>
		<div class="arrow-up" :style="{borderColor: color}" />
	</div>
</template>

<script>
if (!scroll) {
	require("smoothscroll-polyfill").polyfill();
}
export default {
	props: ["width", "color", "scroll-target"],
	computed: {
		parseWidth() {
			const { width } = this;
			if (width) {
				return ["px", "%", "em", "rem", "vw", "vh", "vmin", "vmax", "ch", "cm", "mm", "in", "pt", "pc"]
					.some(unit => width.includes(unit)) ? width : `${width}px`;
			}
		}
	},
	methods: {
		bockToTop() {
			if (this.scrollTarget) this.scrollTarget.scroll({ top: 0, left: 0, behavior: "smooth" });
			else scroll({ top: 0, left: 0, behavior: "smooth" });
		}
	}
}
</script>

<style lang="scss" scoped>
.back-to-top {
	width: 30px;
	white-space: nowrap;
	cursor: pointer;
	user-select: none;
	font-size: 0.8em;
	color: $theme;
	height: 0;

	.arrow-up {
		margin: 0;
		width: 100%;
		border-style: solid;
		border-width: 2px 2px 0 0;
		border-color: $theme;
		background: transparent;
		transform: rotate(-45deg) skew(10deg, 10deg);

		&::before {
			content: "";
			display: block;
			width: 100%;
			padding-top: 100%;
		}
	}
}
</style>